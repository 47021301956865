.services {
    display: inline-block;
    width: 100%;
    padding: 4rem 1.5rem;
    background-color: $greylight;
    .col-left {
        margin-bottom: 2rem;
        text-align: right; }
    .col-right {
        h2 {
            margin-bottom: 3rem;
            color: $green;
            text-align: center; }
        p {
            margin-bottom: 3rem;
            font-weight: 600;
            color: $green;
            br {
                display: none; } }
        ul {
            li {
                padding-left: 2rem;
                margin-bottom: 1rem;
                font-size: 1.4rem;
                font-weight: 600;
                color: $grey;
                background-image: url('../img/icons/icon--check.png');
                background-position: left .5rem;
                background-repeat: no-repeat;
                &:last-child {
                    margin-bottom: 0; } } } } }
@include media-breakpoint-up(sm) {
    .services {
        .col-left {
            text-align: center;
            figure {
                img {
                    float: none; } } } } }
@include media-breakpoint-up(lg) {
    .services {
        padding: 10rem 0 4rem 0;
        margin-top: -6rem;
        .col-left {
            padding-right: 2rem;
            margin-bottom: 0;
            figure {
                img {
                    float: right; } } }
        .col-right {
            display: flex;
            align-items: center;
            padding-left: 2rem;
            h2 {
                margin-bottom: 4rem;
                text-align: left; }
            p {
                br {
                    display: block; } }
            ul {
                float: left;
                padding-right: 3rem;
                width: 50%; } } } }
