.partners {
    padding: 4rem 1.5rem;
    text-align: center;
    h2 {
        margin-bottom: 3rem;
        color: $green; } }
@include media-breakpoint-up(lg) {
    .partners {
        padding: 6rem 0;
        h2 {
            margin-bottom: 4rem; } } }
