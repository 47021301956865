* {
  outline: 0; }
body {
  margin: 0;
  &.overflow {
    overflow-y: hidden; } }
ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none; } }
a {
  transition: .1s all linear;
  text-decoration: none;
  &:hover {
    text-decoration: none; } }
img {
  max-width: 100%;
  height: auto; }
h2, h3, h4, h5, h6, p, figure {
  margin: 0; }
h2 {
  font-size: 2.8rem;
  font-weight: 700;
  color: $greenlight;
  letter-spacing: -.1rem; }
h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: $green;
  letter-spacing: -.1rem; }
