.our-team {
    padding: 4rem 1.5rem 0 1.5rem;
    h2 {
        margin-bottom: 3rem;
        color: $green;
        text-align: center; }
    .team {
        margin-top: 4rem;
        .item {
            .picture {
                position: relative;
                figure {
                    position: relative;
                    margin-bottom: 2rem;
                    border-radius: 1rem;
                    overflow: hidden;
                    z-index: 1;
                    img {
                        display: block; } }
                &::after {
                    content: '';
                    position: absolute;
                    left: -.5rem;
                    bottom: -.5rem;
                    width: 60%;
                    height: 50%;
                    background-color: $greenlight;
                    border-radius: 1.5rem; } }
            .info {
                text-align: left;
                h4 {
                    margin-bottom: 1rem;
                    font-size: 1.8rem;
                    font-weight: 700;
                    color: $greenlight; }
                p {
                    font-size: 1.4rem;
                    span {
                        display: none; } } }
            &:nth-of-type(even) {
                margin-top: 6rem;
                .picture {
                    &::after {
                        left: inherit;
                        right: -.5rem; } } } } } }
@include media-breakpoint-up(lg) {
    .our-team {
        padding: 4rem 0 0;
        text-align: center;
        h2 {
            margin-bottom: 4rem; }
        .team {
            margin-top: 6rem;
            .item {
                padding: 0 3rem 3rem 3rem;
                .picture {
                    figure {
                        border-radius: 3rem;
                        margin-bottom: 3.5rem; }
                    &::after {
                        left: -1.5rem;
                        bottom: -1.5rem;
                        border-radius: 4rem; } }
                .info {
                    padding-left: 3rem;
                    h4 {
                        margin-bottom: 1.5rem; }
                    p {
                        font-size: 1.6rem;
                        span {
                            display: initial; } } }
                &:nth-of-type(even) {
                    margin-top: 10rem;
                    .picture {
                        &::after {
                            right: -1.5rem; } } } } } } }
