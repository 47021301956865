.projects {
    padding-top: 4rem;
    text-align: center;
    h2 {
        margin-bottom: 3rem;
        color: $green; }
    .slide-projects {
        margin: 0 -1.5rem;
        .slick-track {
            display: flex !important;
            .slick-slide {
                padding: 1.5rem .75rem;
                height: inherit;
                .slide-item {
                    height: 100%;
                    display: inline-block;
                    padding: 4rem 0;
                    width: 100%;
                    height: 100%;
                    background-color: $white;
                    border-radius: .8rem;
                    border: .1rem solid $greyregular;
                    box-shadow: 0 0 1rem 1rem rgba($green, .05);
                    transition: .25s all linear;
                    .icon {
                        display: inline-block;
                        margin-bottom: 2rem;
                        width: 4.7rem;
                        height: 4.2rem;
                        background-position: center;
                        background-repeat: no-repeat; }
                    &.item-1 {
                        .icon {
                            background-image: url('../img/icons/icon--building.svg'); }
                        &:hover {
                            .icon {
                                background-image: url('../img/icons/icon--building_hover.svg'); } } }
                    &.item-2 {
                        .icon {
                            background-image: url('../img/icons/icon--house.svg'); }
                        &:hover {
                            .icon {
                                background-image: url('../img/icons/icon--house_hover.svg'); } } }
                    &.item-3 {
                        .icon {
                            background-image: url('../img/icons/icon--teeth.svg'); }
                        &:hover {
                            .icon {
                                background-image: url('../img/icons/icon--teeth_hover.svg'); } } }
                    h3 {
                        position: relative;
                        margin-bottom: 6rem;
                        font-size: 2.4rem;
                        font-weight: 700;
                        color: $green;
                        text-transform: uppercase;
                        &::after {
                            content: '';
                            position: absolute;
                            left: 50%;
                            bottom: -3rem;
                            margin-left: -1.5rem;
                            width: 3rem;
                            height: .2rem;
                            background-color: $greenlight; } }
                    a {
                        font-weight: 600;
                        color: $greenlight;
                        text-decoration: underline; }
                    &:hover {
                        background-color: $green;
                        h3 {
                            color: $white; } } } } }
        .slick-dots {
            display: inline-flex;
            justify-content: center;
            margin-top: 1rem;
            width: 100%;
            z-index: 1;
            li {
                display: inline-block;
                margin: 0 .6rem;
                button {
                    float: left;
                    padding: 0;
                    font-size: 0;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    border: .1rem solid $green;
                    background-color: transparent;
                    cursor: pointer; }
                &.slick-active {
                    button {
                        background-color: $green; } } } } } }
@include media-breakpoint-up(lg) {
    .projects {
        padding: 6rem 0 2rem 0;
        h2 {
            margin-bottom: 4rem; }
        .slide-projects {
            .slick-track {
                .slick-slide {
                    padding: 1.5rem;
                    .slide-item {
                        a {
                            text-decoration: none;
                            &:hover {
                             text-decoration: underline; } } } } } } } }
