header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100vw;
    height: 6rem;
    background-color: $grey;
    z-index: 1;
    &.opened {
        position: fixed;
        z-index: 10; }
    .menu-nav {
        position: absolute;
        top: 50%;
        right: 2.5rem;
        width: 3rem;
        height: 3rem;
        margin-top: -1.5rem;
        z-index: 1;
        &::before,
        span,
        &::after {
            content: '';
            position: absolute;
            right: 0;
            width: 3rem;
            height: .2rem;
            background-color: $greenlight; }
        &::before {
            top: .4rem; }
        span {
            top: 50%;
            margin-top: -.1rem; }
        &::after {
            bottom: .4rem; }
        &.opened {
            &::before,
            &::after {
                top: 1.4rem;
                left: -.2rem;
                right: inherit;
                width: 3.3rem; }
            &::before {
                transform: rotate(-45deg); }
            span {
                display: none; }
            &::after {
                transform: rotate(45deg); } } }
    .brand {
        float: left;
        max-width: 10rem; }
    nav {
        display: none;
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        padding: 6rem 3rem;
        background-color: $grey;
        &.opened {
            display: flex; }
        ul {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 100%;
            li {
                display: inline-block;
                margin-top: 3rem;
                font-size: 1.8rem;
                font-weight: 700;
                text-transform: uppercase;
                a {
                    color: $white;
                    &:hover {
                        color: $greenlight; } }
                &.link--instagram {
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 3.5rem;
                        height: 3.5rem;
                        border-radius: 50%;
                        background-color: $greenlight;
                        img {
                            width: 1.5rem; }
                        &:hover {
                            background-color: $greenregular; } } } } } } }
@include media-breakpoint-up(lg) {
    header {
        display: inline-flex;
        padding: 2rem 0;
        width: 100%;
        height: auto;
        .menu-nav {
            display: none; }
        .brand {
            max-width: 15rem; }
        nav {
            position: relative;
            display: flex;
            float: right;
            padding: 0;
            width: auto;
            height: 6rem;
            ul {
                flex-direction: row;
                align-items: center;
                li {
                    margin: 0 1.5rem; } } } } }

