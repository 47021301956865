html {
	font-size: 62.5%; }
body {
	font-size: 1.6rem;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased; }
h2, h3, h4, h5, h6 {
	font-weight: normal;
	line-height: 1.2; }
p {
	line-height: 1.5; }
strong {
	font-weight: 700; }
address {
	font-style: normal; }
