.projects-single {
    padding: 4rem 1.5rem;
    text-align: center;
    h2 {
        margin-bottom: 3rem;
        color: $green; }
    .header-project {
        padding: 2rem 3rem;
        text-align: left;
        color: $white;
        background-color: $grey;
        h4 {
            font-size: 1.2rem;
            font-weight: 600;
            text-transform: uppercase; }
        > div {
            margin-top: 1.5rem;
            padding-top: 1.5rem;
            font-size: 1.1rem;
            border-top: .2rem solid $greensemiregular; } }
    .gallery {
        display: inline-block;
        padding: 3rem 0;
        width: 100%;
        .slider-gallery {
            margin-bottom: 3rem;
            .slick-track {
                display: flex; }
            .slick-slide {
                display: flex!important;
                align-items: center;
                justify-content: center;
                height: inherit !important;
                img {
                    object-fit: cover; } } }
        .slider-thumbs {
            position: relative;
            .slick-slide {
                margin: 0 .5rem;
                cursor: pointer; } }
        .slick-dots {
            display: inline-flex;
            justify-content: center;
            margin-top: 3rem;
            width: 100%;
            z-index: 1;
            li {
                display: inline-block;
                margin: 0 .6rem;
                button {
                    float: left;
                    padding: 0;
                    font-size: 0;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    border: .1rem solid $green;
                    background-color: transparent;
                    cursor: pointer; }
                &.slick-active {
                    button {
                        background-color: $green; } } } } } }
@include media-breakpoint-up(lg) {
    .projects-single {
        padding: 6rem 0;
        h2 {
            margin-bottom: 4rem; }
        .header-project {
            padding: 3rem 8rem;
            h4 {
                font-size: 1.6rem; }
            > div {
                margin-top: 2rem;
                padding-top: 2rem;
                font-size: 1.4rem;
                border-top-width: .4rem; } }
        .gallery {
            padding: 4rem 8rem;
            .slider-gallery {
                float: left;
                margin-bottom: 0;
                width: 80rem;
                .slick-track {
                    height: 53.3rem; }
                .slick-slide {
                    img {
                        max-height: 100%; } } }
            .slider-thumbs {
                position: relative;
                float: right;
                display: flex;
                align-items: center;
                width: 12rem;
                height: 53.3rem;
                .slick-slide {
                    margin: .5rem 0;
                    width: 12rem; }
                .slick-arrow {
                    position: absolute;
                    left: 50%;
                    margin-left: -1.25rem;
                    width: 2.5rem;
                    height: 2.5rem;
                    font-size: 0;
                    background: none;
                    border: none;
                    cursor: pointer;
                    &.slick-prev {
                        top: 0;
                        background: url('../img/icons/icon--arrow-top.png'); }
                    &.slick-next {
                        bottom: 0;
                        background: url('../img/icons/icon--arrow-bottom.png'); }
                    &.slick-disabled {
                        display: none !important; } } } } } }
