.projects-list {
    padding: 4rem 1.5rem;
    text-align: center;
    h2 {
        margin-bottom: 3rem;
        color: $green; }
    .filter {
        margin-bottom: 3rem;
        .filter-link {
            position: relative;
            display: inline-block;
            margin: .5rem 1rem;
            font-size: 1.4rem;
            font-weight: 700;
            text-transform: uppercase;
            color: $black;
            cursor: pointer;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -.3rem;
                width: 100%;
                height: .2rem;
                background-color: transparent; }
            &.active {
                color: $green;
                &::after {
                    background-color: $green; } } } }
    .wrap {
        display: none;
        grid-template-columns: 1fr 1fr;
        gap: 1.5rem 1.5rem;
        grid-template-areas: ". .";
        margin-bottom: 1.5rem;
        .item-project {
            position: relative;
            img {
                display: block; }
            .infos {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1.5rem;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($green,.9);
                visibility: hidden;
                opacity: 0;
                transition: visibility 0.2s linear, opacity 0.2s linear;
                strong {
                    display: block;
                    margin-bottom: 1.8rem;
                    position: relative;
                    font-size: 1.2rem;
                    font-weight: 700;
                    color: $white;
                    text-transform: uppercase;
                    &::after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        bottom: -1rem;
                        margin-left: -1rem;
                        width: 2rem;
                        height: .2rem;
                        background-color: $greenlight; } }
                span {
                    display: block;
                    margin-bottom: 1rem;
                    font-size: 1rem;
                    font-weight: 600;
                    color: $white; }
                a {
                    display: inline-block;
                    width: 2rem;
                    height: 2rem;
                    background-image: url('../img/icons/icon--plus.png');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    &:hover,
                    &:active,
                    &:focus {
                        background-image: url('../img/icons/icon--plus_hover.png'); } } }
            &:hover {
                .infos {
                    visibility: visible;
                    opacity: 1; } } }
        &.active {
            display: grid; }
        &:last-child {
            margin-bottom: 0; } } }
@include media-breakpoint-up(lg) {
    .projects-list {
        padding: 6rem 0;
        h2 {
            margin-bottom: 4rem; }
        .filter {
            margin-bottom: 5rem;
            .filter-link {
                font-size: 1.8rem;
                margin: 0 4rem;
                &::after {
                    bottom: -.5rem;
                    height: .3rem; } } }
        .wrap {
            grid-template-columns: 1fr 1fr 1fr;
            gap: 2.5rem 2.5rem;
            grid-template-areas: ". . .";
            margin-bottom: 2.5rem;
            .item-project {
                .infos {
                    padding: 2rem;
                    strong {
                        margin-bottom: 5.7rem;
                        font-size: 2rem;
                        &::after {
                            bottom: -3rem;
                            width: 4rem;
                            height: .3rem;
                            margin-left: -2rem; } }
                    span {
                        margin-bottom: 3rem;
                        font-size: 1.4rem; }
                    a {
                        width: 3.5rem;
                        height: 3.5rem; } } } } } }

