.about {
    position: relative;
    padding: 4rem 1.5rem;
    background-color: $grey;
    background-image: url('../img/dots--slide.png');
    background-repeat: no-repeat;
    background-position: 5vw 65%;
    h2 {
        text-align: center;
        margin-bottom: 3rem; }
    p {
        color: $white; }
    .slide-about {
        margin: 3rem -3rem 0 -3rem;
        .slick-track {
            display: flex !important; }
        .slick-slide {
            padding: 0 .75rem;
            height: inherit;
            .slide-item {
                padding: 3rem 1.5rem;
                height: 100%;
                text-align: center;
                background-color: $white;
                border-radius: 1rem;
                img {
                    max-width: 6rem; }
                h3 {
                    margin: 2rem 0 1.5rem 0; }
                p {
                    font-size: 1.4rem;
                    color: $grey; } }
            img {
                display: inline-block; } }
        .slick-dots {
            display: inline-flex;
            justify-content: center;
            margin-top: 1.5rem;
            width: 100%;
            li {
                display: inline-block;
                margin: 0 .6rem;
                button {
                    padding: 0;
                    font-size: 0;
                    width: .6rem;
                    height: .6rem;
                    border-radius: 50%;
                    border: none;
                    background-color: $greenlight;
                    cursor: pointer; }
                &.slick-active {
                    button {
                        width: 1rem;
                        height: 1rem; } } } }
        .slick-arrow {
            position: absolute;
            top: 50%;
            margin-top: -2.5rem;
            width: 2.5rem;
            height: 2.5rem;
            font-size: 0;
            background: none;
            border: none;
            cursor: pointer;
            &.slick-prev {
                left: -4rem;
                background: url('../img/icons/icon--arrow-left.png'); }
            &.slick-next {
                right: -4rem;
                background: url('../img/icons/icon--arrow-right.png'); }
            &.slick-disabled {
                display: none !important; } } } }

@include media-breakpoint-up(lg) {
    .about {
        margin: 0 3vw;
        padding: 4rem 0;
        background-position: 4vw 20rem;
        text-align: center;
        .slide-about {
            margin: 3rem 0 0;
            .slick-slide {
                padding: 0 1.5rem;
                .slide-item {
                    padding: 3rem 4rem; } } } } }
