footer {
    padding: 4rem 1.5rem 0 1.5rem;
    text-align: center;
    background-color: $green;
    .brand {
        display: block;
        margin: 0 auto;
        max-width: 15rem; }
    .phone {
        margin: 3rem 0 2rem 0;
        color: $white;
        .item {
            margin-bottom: 1.5rem;
            span {
                margin-right: .5rem;
                padding: .3rem .9rem;
                font-size: 1.1rem;
                text-transform: uppercase;
                background-color: $greenlight;
                border-radius: .3rem; }
            a {
                color: $white;
                text-decoration: underline; }
            &:last-child {
                margin-bottom: 0; } } }
    .contact {
        .email {
            font-weight: 700;
            color: $greenlight;
            &:hover {
                text-decoration: underline; } }
        address {
            margin: 1.5rem 0;
            color: $white; }
        .social {
            display: flex;
            flex-direction: column;

            margin-top: 3rem;
            > div {
                display: flex;
                justify-content: center;
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 1rem;
                    width: 3.5rem;
                    height: 3.5rem;
                    border-radius: 50%;
                    background-color: $greenlight;
                    img {
                        width: 1.5rem; }
                    &:hover {
                        background-color: $greenregular; } } }
            strong {
                margin-top: 1rem;
                font-size: 1.2rem;
                font-weight: 700;
                color: $greenlight;
                text-transform: uppercase; } } }

    .base {
        display: flex;
        justify-content: flex-end;
        padding: 2rem 0;
        margin-top: 3rem;
        border-top: .1rem solid $white;
        img {
            display: block; } } }
@include media-breakpoint-up(sm) {
    footer {
        padding: 6rem 0 0;
        text-align: left;
        .brand {
            margin: 0;
            max-width: 15rem; }
        .phone {
            margin: 0;
            .item {
                a {
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline; } } } }
        .contact {
            .social {
                flex-direction: row;
                justify-content: flex-start;
                margin-top: 0;
                strong {
                    display: none; } } }
        .base {
            padding: 3rem 0;
            margin-top: 4rem; } } }
