.hero {
    position: relative;
    overflow: hidden;
    .slide-hero {
        .slick-slide {
            display: flex;
            align-items: center;
            padding: 0 1.5rem;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: calc(100vh - 6rem);
            height: calc(var(--vh, 1vh) * 100 - 6rem);
            h3 {
                font-size: 3rem;
                color: $white;
                br {
                    display: none; } }
            &.slide-1 {
                background-image: url('../img/slide--hero_01_xs.jpg');
                h3 {
                    color: $grey;
                    span {
                        color: $green; } } }
            &.slide-2 {
                background-image: url('../img/slide--hero_02_xs.jpg'); }
            &.slide-3 {
                background-image: url('../img/slide--hero_03_xs.jpg'); }
            &.slide-4 {
                background-image: url('../img/slide--hero_04_xs.jpg'); }
            .btn {
                position: relative;
                margin-top: 2rem;
                z-index: 1; } }
        .slick-dots {
            position: absolute;
            bottom: 4rem;
            display: inline-flex;
            justify-content: center;
            width: 100%;
            z-index: 1;
            li {
                display: inline-block;
                margin: 0 .6rem;
                button {
                    float: left;
                    padding: 0;
                    font-size: 0;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    border: .1rem solid $white;
                    background-color: transparent;
                    cursor: pointer; }
                &.slick-active {
                    button {
                        background-color: $white; } } } } }
    .overflow--slide {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 50%;
        img {
            position: absolute;
            right: 0;
            bottom: 0; } } }
@include media-breakpoint-up(sm) {
    .hero {
        .slide-hero {
            .slick-slide {
                h3 {
                    font-size: 5.4rem; } } } } }
@include media-breakpoint-up(lg) {
    .hero {
        margin-bottom: 6rem;
        .slide-hero {
            .slick-slide {
                padding: 0;
                height: calc(100vh - 10rem);
                h3 {
                    br {
                        display: block; } }
                &.slide-1 {
                    background-image: url('../img/slide--hero_01_lg.jpg'); }
                &.slide-2 {
                    background-image: url('../img/slide--hero_02_lg.jpg'); }
                &.slide-3 {
                    background-image: url('../img/slide--hero_03_lg.jpg'); }
                &.slide-4 {
                    background-image: url('../img/slide--hero_04_lg.jpg'); } } }
        .overflow--slide {
            width: auto;
            height: 100%; } } }
